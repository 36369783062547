	.icon {
		text-decoration: none;
		border-bottom: none;
		position: relative;
		text-align: center;
	}

		.icon:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-family: FontAwesome;
			font-style: normal;
			font-weight: normal;
			text-transform: none !important;
		}

		.icon > .label {
			display: none;
		}

		.icon.style2:before {
			border-radius: 2.75em;
			display: inline-block;
			height: 2.75em;
			line-height: 2.75em;
			width: 2.75em;
		}

		.icon.major {
			display: block;
			margin: 0 0 1rem 0;
		}

			.icon.major:before {
				font-size: 1.25rem;
			}

	a.icon.style2:before {
		-moz-transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
		-webkit-transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
		-ms-transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
	}

	.icon.style2:before {
		box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
	}

	a.icon.style2:hover:before {
		box-shadow: inset 0 0 0 1px #47D3E5;
		color: #47D3E5;
	}

	a.icon.style2:active:before {
		background-color: rgba(71, 211, 229, 0.1);
		box-shadow: inset 0 0 0 1px #47D3E5;
		color: #47D3E5;
	}

/* Image */

	.image {
		border: 0;
		border-radius: 4px;
		display: inline-block;
		position: relative;
	}

		.image img {
			display: block;
			border-radius: 4px;
		}

		.image.left, .image.right {
			width: 40%;
			max-width: 10rem;
		}

			.image.left img, .image.right img {
				width: 100%;
			}

		.image.left {
			float: left;
			margin: 0 1.5rem 1rem 0;
			top: 0.25rem;
		}

		.image.right {
			float: right;
			margin: 0 0 1rem 1.5rem;
			top: 0.25rem;
		}

		.image.fit {
			display: block;
			margin: 0 0 2rem 0;
			width: 100%;
		}

			.image.fit img {
				width: 100%;
			}

		.image.main {
			display: block;
			margin: 0 0 3rem 0;
			width: 100%;
		}

			.image.main img {
				width: 100%;
			}

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 2rem 0;
		padding-left: 1.25rem;
	}

		ol li {
			padding-left: 0.25rem;
		}

	ul {
		list-style: disc;
		margin: 0 0 2rem 0;
		padding-left: 1rem;
	}

		ul li {
			padding-left: 0.5rem;
		}

		ul.alt {
			list-style: none;
			padding-left: 0;
		}

			ul.alt li {
				border-top: solid 1px;
				padding: 0.5rem 0;
			}

				ul.alt li:first-child {
					border-top: 0;
					padding-top: 0;
				}

	dl {
		margin: 0 0 2rem 0;
	}

		dl dt {
			display: block;
			font-weight: 400;
			margin: 0 0 1rem 0;
		}

		dl dd {
			margin-left: 2rem;
		}

		dl.style2 dt {
			width: 25%;
			float: left;
		}

		dl.style2 dd {
			width: 70%;
			float: left;
		}

		dl.style2:after {
			content: '';
			display: block;
			clear: both;
		}

	ul.alt li {
		border-top-color: rgba(0, 0, 0, 0.2);
	}

/* Actions */

	ul.actions {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		cursor: default;
		list-style: none;
		margin-left: -1rem;
		padding-left: 0;
	}

		ul.actions li {
			padding: 0 0 0 1rem;
			vertical-align: middle;
		}

		ul.actions.special {
			-moz-justify-content: center;
			-webkit-justify-content: center;
			-ms-justify-content: center;
			justify-content: center;
			width: 100%;
			margin-left: 0;
		}

			ul.actions.special li:first-child {
				padding-left: 0;
			}

		ul.actions.stacked {
			-moz-flex-direction: column;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			margin-left: 0;
		}

			ul.actions.stacked li {
				padding: 1.3rem 0 0 0;
			}

				ul.actions.stacked li:first-child {
					padding-top: 0;
				}

		ul.actions.fit {
			width: calc(100% + 1rem);
		}

			ul.actions.fit li {
				-moz-flex-grow: 1;
				-webkit-flex-grow: 1;
				-ms-flex-grow: 1;
				flex-grow: 1;
				-moz-flex-shrink: 1;
				-webkit-flex-shrink: 1;
				-ms-flex-shrink: 1;
				flex-shrink: 1;
				width: 100%;
			}

				ul.actions.fit li > * {
					width: 100%;
				}

			ul.actions.fit.stacked {
				width: 100%;
			}

		@media screen and (max-width: 480px) {

			ul.actions:not(.fixed) {
				-moz-flex-direction: column;
				-webkit-flex-direction: column;
				-ms-flex-direction: column;
				flex-direction: column;
				margin-left: 0;
				width: 100% !important;
			}

				ul.actions:not(.fixed) li {
					-moz-flex-grow: 1;
					-webkit-flex-grow: 1;
					-ms-flex-grow: 1;
					flex-grow: 1;
					-moz-flex-shrink: 1;
					-webkit-flex-shrink: 1;
					-ms-flex-shrink: 1;
					flex-shrink: 1;
					padding: 1rem 0 0 0;
					text-align: center;
					width: 100%;
				}

					ul.actions:not(.fixed) li > * {
						width: 100%;
					}

					ul.actions:not(.fixed) li:first-child {
						padding-top: 0;
					}

					ul.actions:not(.fixed) li input[type="submit"],
					ul.actions:not(.fixed) li input[type="reset"],
					ul.actions:not(.fixed) li input[type="button"],
					ul.actions:not(.fixed) li button,
					ul.actions:not(.fixed) li .button {
						width: 100%;
					}

						ul.actions:not(.fixed) li input[type="submit"].icon:before,
						ul.actions:not(.fixed) li input[type="reset"].icon:before,
						ul.actions:not(.fixed) li input[type="button"].icon:before,
						ul.actions:not(.fixed) li button.icon:before,
						ul.actions:not(.fixed) li .button.icon:before {
							margin-left: -0.5rem;
						}

		}

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;
	}

		ul.icons li {
			display: inline-block;
			padding: 0 0.75rem 0 0;
		}

			ul.icons li:last-child {
				padding-right: 0;
			}

/* Section/Article */

	section.special, article.special {
		text-align: center;
	}

	header p {
		position: relative;
		margin: -0.65rem 0 1.5rem 0;
		font-style: italic;
	}

	header h1 + p {
		font-size: 1.375rem;
	}

	header h2 + p {
		font-size: 1.25rem;
	}

	header h3 + p {
		font-size: 1.1rem;
	}

	header h4 + p,
	header h5 + p,
	header h6 + p {
		font-size: 0.9rem;
	}

	header p {
		color: rgba(0, 0, 0, 0.75);
	}

/* Banner (transitions) */

	.banner.onload-content-fade-up .content {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

	body.is-preload .banner.onload-content-fade-up .content {
		-moz-transform: translateY(1rem);
		-webkit-transform: translateY(1rem);
		-ms-transform: translateY(1rem);
		transform: translateY(1rem);
		opacity: 0;
	}

	.banner.onload-content-fade-down .content {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

	body.is-preload .banner.onload-content-fade-down .content {
		-moz-transform: translateY(-1rem);
		-webkit-transform: translateY(-1rem);
		-ms-transform: translateY(-1rem);
		transform: translateY(-1rem);
		opacity: 0;
	}

	.banner.onload-content-fade-left .content {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

	body.is-preload .banner.onload-content-fade-left .content {
		-moz-transform: translateX(1rem);
		-webkit-transform: translateX(1rem);
		-ms-transform: translateX(1rem);
		transform: translateX(1rem);
		opacity: 0;
	}

	.banner.onload-content-fade-right .content {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

	body.is-preload .banner.onload-content-fade-right .content {
		-moz-transform: translateX(-1rem);
		-webkit-transform: translateX(-1rem);
		-ms-transform: translateX(-1rem);
		transform: translateX(-1rem);
		opacity: 0;
	}

	.banner.onload-content-fade-in .content {
		-moz-transition: opacity 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out;
	}

	body.is-preload .banner.onload-content-fade-in .content {
		opacity: 0;
	}

	.banner.onload-image-fade-up .image {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

		.banner.onload-image-fade-up .image img {
			-moz-transition: opacity 0.75s ease-in-out;
			-webkit-transition: opacity 0.75s ease-in-out;
			-ms-transition: opacity 0.75s ease-in-out;
			transition: opacity 0.75s ease-in-out;
			-moz-transition-delay: 0.5625s;
			-webkit-transition-delay: 0.5625s;
			-ms-transition-delay: 0.5625s;
			transition-delay: 0.5625s;
		}

	body.is-preload .banner.onload-image-fade-up .image {
		-moz-transform: translateY(1rem);
		-webkit-transform: translateY(1rem);
		-ms-transform: translateY(1rem);
		transform: translateY(1rem);
		opacity: 0;
	}

		body.is-preload .banner.onload-image-fade-up .image img {
			opacity: 0;
		}

	.banner.onload-image-fade-down .image {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

		.banner.onload-image-fade-down .image img {
			-moz-transition: opacity 0.75s ease-in-out;
			-webkit-transition: opacity 0.75s ease-in-out;
			-ms-transition: opacity 0.75s ease-in-out;
			transition: opacity 0.75s ease-in-out;
			-moz-transition-delay: 0.5625s;
			-webkit-transition-delay: 0.5625s;
			-ms-transition-delay: 0.5625s;
			transition-delay: 0.5625s;
		}

	body.is-preload .banner.onload-image-fade-down .image {
		-moz-transform: translateY(-1rem);
		-webkit-transform: translateY(-1rem);
		-ms-transform: translateY(-1rem);
		transform: translateY(-1rem);
		opacity: 0;
	}

		body.is-preload .banner.onload-image-fade-down .image img {
			opacity: 0;
		}

	.banner.onload-image-fade-left .image {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

		.banner.onload-image-fade-left .image img {
			-moz-transition: opacity 0.75s ease-in-out;
			-webkit-transition: opacity 0.75s ease-in-out;
			-ms-transition: opacity 0.75s ease-in-out;
			transition: opacity 0.75s ease-in-out;
			-moz-transition-delay: 0.5625s;
			-webkit-transition-delay: 0.5625s;
			-ms-transition-delay: 0.5625s;
			transition-delay: 0.5625s;
		}

	body.is-preload .banner.onload-image-fade-left .image {
		-moz-transform: translateX(1rem);
		-webkit-transform: translateX(1rem);
		-ms-transform: translateX(1rem);
		transform: translateX(1rem);
		opacity: 0;
	}

		body.is-preload .banner.onload-image-fade-left .image img {
			opacity: 0;
		}

	.banner.onload-image-fade-right .image {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

		.banner.onload-image-fade-right .image img {
			-moz-transition: opacity 0.75s ease-in-out;
			-webkit-transition: opacity 0.75s ease-in-out;
			-ms-transition: opacity 0.75s ease-in-out;
			transition: opacity 0.75s ease-in-out;
			-moz-transition-delay: 0.5625s;
			-webkit-transition-delay: 0.5625s;
			-ms-transition-delay: 0.5625s;
			transition-delay: 0.5625s;
		}

	body.is-preload .banner.onload-image-fade-right .image {
		-moz-transform: translateX(-1rem);
		-webkit-transform: translateX(-1rem);
		-ms-transform: translateX(-1rem);
		transform: translateX(-1rem);
		opacity: 0;
	}

		body.is-preload .banner.onload-image-fade-right .image img {
			opacity: 0;
		}

	.banner.onload-image-fade-in .image img {
		-moz-transition: opacity 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out;
	}

	body.is-preload .banner.onload-image-fade-in .image img {
		opacity: 0;
	}

	.banner.onscroll-content-fade-up .content {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

	.banner.onscroll-content-fade-up.is-inactive .content {
		-moz-transform: translateY(1rem);
		-webkit-transform: translateY(1rem);
		-ms-transform: translateY(1rem);
		transform: translateY(1rem);
		opacity: 0;
	}

	.banner.onscroll-content-fade-down .content {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

	.banner.onscroll-content-fade-down.is-inactive .content {
		-moz-transform: translateY(-1rem);
		-webkit-transform: translateY(-1rem);
		-ms-transform: translateY(-1rem);
		transform: translateY(-1rem);
		opacity: 0;
	}

	.banner.onscroll-content-fade-left .content {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

	.banner.onscroll-content-fade-left.is-inactive .content {
		-moz-transform: translateX(1rem);
		-webkit-transform: translateX(1rem);
		-ms-transform: translateX(1rem);
		transform: translateX(1rem);
		opacity: 0;
	}

	.banner.onscroll-content-fade-right .content {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

	.banner.onscroll-content-fade-right.is-inactive .content {
		-moz-transform: translateX(-1rem);
		-webkit-transform: translateX(-1rem);
		-ms-transform: translateX(-1rem);
		transform: translateX(-1rem);
		opacity: 0;
	}

	.banner.onscroll-content-fade-in .content {
		-moz-transition: opacity 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out;
	}

	.banner.onscroll-content-fade-in.is-inactive .content {
		opacity: 0;
	}

	.banner.onscroll-image-fade-up .image {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

		.banner.onscroll-image-fade-up .image img {
			-moz-transition: opacity 0.75s ease-in-out;
			-webkit-transition: opacity 0.75s ease-in-out;
			-ms-transition: opacity 0.75s ease-in-out;
			transition: opacity 0.75s ease-in-out;
			-moz-transition-delay: 0.5625s;
			-webkit-transition-delay: 0.5625s;
			-ms-transition-delay: 0.5625s;
			transition-delay: 0.5625s;
		}

	.banner.onscroll-image-fade-up.is-inactive .image {
		-moz-transform: translateY(1rem);
		-webkit-transform: translateY(1rem);
		-ms-transform: translateY(1rem);
		transform: translateY(1rem);
		opacity: 0;
	}

		.banner.onscroll-image-fade-up.is-inactive .image img {
			opacity: 0;
		}

	.banner.onscroll-image-fade-down .image {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

		.banner.onscroll-image-fade-down .image img {
			-moz-transition: opacity 0.75s ease-in-out;
			-webkit-transition: opacity 0.75s ease-in-out;
			-ms-transition: opacity 0.75s ease-in-out;
			transition: opacity 0.75s ease-in-out;
			-moz-transition-delay: 0.5625s;
			-webkit-transition-delay: 0.5625s;
			-ms-transition-delay: 0.5625s;
			transition-delay: 0.5625s;
		}

	.banner.onscroll-image-fade-down.is-inactive .image {
		-moz-transform: translateY(-1rem);
		-webkit-transform: translateY(-1rem);
		-ms-transform: translateY(-1rem);
		transform: translateY(-1rem);
		opacity: 0;
	}

		.banner.onscroll-image-fade-down.is-inactive .image img {
			opacity: 0;
		}

	.banner.onscroll-image-fade-left .image {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

		.banner.onscroll-image-fade-left .image img {
			-moz-transition: opacity 0.75s ease-in-out;
			-webkit-transition: opacity 0.75s ease-in-out;
			-ms-transition: opacity 0.75s ease-in-out;
			transition: opacity 0.75s ease-in-out;
			-moz-transition-delay: 0.5625s;
			-webkit-transition-delay: 0.5625s;
			-ms-transition-delay: 0.5625s;
			transition-delay: 0.5625s;
		}

	.banner.onscroll-image-fade-left.is-inactive .image {
		-moz-transform: translateX(1rem);
		-webkit-transform: translateX(1rem);
		-ms-transform: translateX(1rem);
		transform: translateX(1rem);
		opacity: 0;
	}

		.banner.onscroll-image-fade-left.is-inactive .image img {
			opacity: 0;
		}

	.banner.onscroll-image-fade-right .image {
		-moz-transition: opacity 0.75s ease-in-out, -moz-transform 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out, -webkit-transform 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out, -ms-transform 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out;
	}

		.banner.onscroll-image-fade-right .image img {
			-moz-transition: opacity 0.75s ease-in-out;
			-webkit-transition: opacity 0.75s ease-in-out;
			-ms-transition: opacity 0.75s ease-in-out;
			transition: opacity 0.75s ease-in-out;
			-moz-transition-delay: 0.5625s;
			-webkit-transition-delay: 0.5625s;
			-ms-transition-delay: 0.5625s;
			transition-delay: 0.5625s;
		}

	.banner.onscroll-image-fade-right.is-inactive .image {
		-moz-transform: translateX(-1rem);
		-webkit-transform: translateX(-1rem);
		-ms-transform: translateX(-1rem);
		transform: translateX(-1rem);
		opacity: 0;
	}

		.banner.onscroll-image-fade-right.is-inactive .image img {
			opacity: 0;
		}

	.banner.onscroll-image-fade-in .image img {
		-moz-transition: opacity 0.75s ease-in-out;
		-webkit-transition: opacity 0.75s ease-in-out;
		-ms-transition: opacity 0.75s ease-in-out;
		transition: opacity 0.75s ease-in-out;
	}

	.banner.onscroll-image-fade-in.is-inactive .image img {
		opacity: 0;
	}

/* Banner (style1) */

	.banner.style1 {
		-moz-align-items: -moz-stretch;
		-webkit-align-items: -webkit-stretch;
		-ms-align-items: -ms-stretch;
		align-items: stretch;
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-direction: row;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-moz-justify-content: -moz-flex-end;
		-webkit-justify-content: -webkit-flex-end;
		-ms-justify-content: -ms-flex-end;
		justify-content: flex-end;
		position: relative;
		text-align: left;
		overflow-x: hidden;
	}

		.banner.style1 .content {
			padding: 7rem 7rem 5rem 7rem ;
			-moz-align-self: center;
			-webkit-align-self: center;
			-ms-align-self: center;
			align-self: center;
			-moz-flex-grow: 1;
			-webkit-flex-grow: 1;
			-ms-flex-grow: 1;
			flex-grow: 1;
			-moz-flex-shrink: 1;
			-webkit-flex-shrink: 1;
			-ms-flex-shrink: 1;
			flex-shrink: 1;
			width: 50%;
			max-width: 48rem;
			margin: 0 auto;
		}

		.banner.style1 .image {
			-moz-flex-grow: 0;
			-webkit-flex-grow: 0;
			-ms-flex-grow: 0;
			flex-grow: 0;
			-moz-flex-shrink: 0;
			-webkit-flex-shrink: 0;
			-ms-flex-shrink: 0;
			flex-shrink: 0;
			border-radius: 0;
			width: 50%;
		}

			.banner.style1 .image img {
				-moz-object-fit: cover;
				-webkit-object-fit: cover;
				-ms-object-fit: cover;
				object-fit: cover;
				-moz-object-position: center;
				-webkit-object-position: center;
				-ms-object-position: center;
				object-position: center;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 0;
			}

		@media screen and (max-width: 1680px) {

			.banner.style1 .content {
				padding: 5rem 5rem 3rem 5rem ;
			}

		}

		@media screen and (max-width: 1280px) {

			.banner.style1 .content {
				padding: 4rem 4rem 2rem 4rem ;
			}

		}

		@media screen and (max-width: 980px) {

			.banner.style1 .content {
				padding: 3.75rem 3rem 1.75rem 3rem ;
			}

		}

		@media screen and (max-width: 736px) {

			.banner.style1 .content {
				padding: 2.5rem 2rem 0.5rem 2rem ;
			}

		}

		@media screen and (orientation: portrait) {

			.banner.style1 {
				-moz-flex-direction: column-reverse;
				-webkit-flex-direction: column-reverse;
				-ms-flex-direction: column-reverse;
				flex-direction: column-reverse;
				text-align: center;
			}

				.banner.style1 .content {
					display: -moz-flex;
					display: -webkit-flex;
					display: -ms-flex;
					display: flex;
					-moz-flex-direction: column;
					-webkit-flex-direction: column;
					-ms-flex-direction: column;
					flex-direction: column;
					-moz-justify-content: center;
					-webkit-justify-content: center;
					-ms-justify-content: center;
					justify-content: center;
					width: 100%;
					max-width: 100%;
				}

				.banner.style1 .image {
					width: 100%;
					max-width: 100%;
					height: 45vh;
				}

		}

		.banner.style1.fullscreen {
			min-height: 100vh;
		}

			@media screen and (orientation: portrait) {

				.banner.style1.fullscreen .content {
					min-height: 50vh;
				}

				.banner.style1.fullscreen .image {
					height: 50vh;
				}

			}

		.banner.style1.orient-right {
			-moz-flex-direction: row-reverse;
			-webkit-flex-direction: row-reverse;
			-ms-flex-direction: row-reverse;
			flex-direction: row-reverse;
		}

			@media screen and (orientation: portrait) {

				.banner.style1.orient-right {
					-moz-flex-direction: column-reverse;
					-webkit-flex-direction: column-reverse;
					-ms-flex-direction: column-reverse;
					flex-direction: column-reverse;
				}

			}

		.banner.style1.content-align-center {
			text-align: center;
		}

		.banner.style1.content-align-right {
			text-align: right;
		}

			@media screen and (orientation: portrait) {

				.banner.style1.content-align-right {
					text-align: center;
				}

			}

		.banner.style1.image-position-left .image img {
			-moz-object-position: left;
			-webkit-object-position: left;
			-ms-object-position: left;
			object-position: left;
		}

		.banner.style1.image-position-right .image img {
			-moz-object-position: right;
			-webkit-object-position: right;
			-ms-object-position: right;
			object-position: right;
		}

