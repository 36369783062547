body{
    font-family: 'Hurme',sans-serif !important;
}

body{
    background: rgb(195,68,86);
    overflow-x: hidden;
}

section{
    position: relative;
    height:100%;
    width:100%;
    padding-bottom:50px; 
    padding-top:50px;
}

section:first-child {
    padding-top: 0px; /* limits the scope of the previous rule */
}

@media (min-width: 992px) {
    section{
        padding-bottom: 100px;
    }
}

a {
    color: #fff !important;
}

a:hover{
    cursor: pointer;
}

a:visited{
    color: #fff;
}
.nav-link{
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
    font-weight: bold !important;
}

::-webkit-input-placeholder{
    color:#c04355;
    font-weight: bold;
    opacity: 0.8;
    padding: 2px;
}

::-moz-placeholder {
    color:#c04355;
    font-weight: bold;
    opacity: 0.8;
    padding: 2px;
}

::placeholder{
    color:#c04355;
    font-weight: bold;
    opacity: 0.8;
    padding: 2px;
}