@font-face {
  font-family: "Hurme";
  src: url("./fonts/Hurme/HurmeGeometricSans3-Regular.eot");
  src: url("./fonts/Hurme/HurmeGeometricSans3-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Hurme/HurmeGeometricSans3-Regular.woff2") format("woff2"), url("./fonts/Hurme/HurmeGeometricSans3-Regular.woff") format("woff"),
    url("./fonts/Hurme/HurmeGeometricSans3-Regular.ttf") format("truetype"),
    url("./fonts/Hurme/HurmeGeometricSans3-Regular.svg#HurmeGeometricSans3-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brush";
  /* src: url("./fonts/Brush/DKCinnabarBrush.eot"); */
  src: url("./fonts/Brush/DKCinnabarBrush.eot");
  src: url("./fonts/Brush/DKCinnabarBrush.woff2") format("woff2"), url("./fonts/Brush/DKCinnabarBrush.woff") format("woff"),
    url("./fonts/Brush/DKCinnabarBrush.ttf") format("truetype"), url("./fonts/Brush/DKCinnabarBrush.svg#DKCinnabarBrush") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Hurme";
  margin: 0;
  padding: 0;
  min-height: 100%;
  min-width: 100%;
}

body {
  background: rgb(195, 68, 86);
  overflow-x: hidden;
}

#main {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../images/jumbo.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}

#main .bag img {
  display: block;
  margin: 0 auto 10px;
  width: 100%;
  height: auto;
  @media screen and (max-width: 860px) {
    height: 100%;
    width: unset;
    position: absolute;
    left: -50%;
  }
  @media screen and (max-width: 768px) {
    height: auto;
    width: 80%;
    position: relative;
    left: unset;
  }
}

#main .text,
#main .bag {
  padding: 10px;
}

@media (min-width: 992px) {
  #main .text,
  #benefits .text,
  #contact .text {
    text-align: left;
  }

  #downarrow {
    padding-top: 50px;
  }
}

#benefits {
  position: relative;
  background-color: #f6f6f6;
}

@media screen and (max-width: 600px) {
  #benefits .title,
  #benefits .sub-title {
    text-align: center;
  }
}

#benefits .title,
#benefits .sub-title {
  text-align: left;
}

#benefits .text {
  position: relative;
  font-weight: 600;
}

#benefits .benefits-cup img {
  position: absolute;
  left: 0%;
  top: 30px;
  z-index: 0;
}

#didyouknow {
  background-color: #fff;
  z-index: 1;
  position: relative;
}
#didyouknow img {
  height: 85px;
  width: auto;
}
#didyouknow .ingredients {
  margin-top: 25px;
  transition: box-shadow 0.3s;
  background: #fff;
  padding: 5px;
}

#didyouknow .ingredients:hover,
#didyouknow .ingredients:active {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
#didyouknow .text {
  padding-top: 20px;
  padding-bottom: 20px;
}

#hotorcold {
  position: relative;
  background-color: #f6f6f6;
}

#hotorcold .text {
  padding-bottom: 50px;
}

.hotorcold-cup {
  margin: 0 auto;
}
#hotorcold img {
  width: 90%;
  text-align: center;
  margin-bottom: -178px;
}

#contact {
  background-color: #fff;
  z-index: 1;
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
}

#contact-left,
#contact-right {
  box-sizing: border-box;
}

#contact .title {
  margin-top: 20px;
}

@media (max-width: 500px) {
  #contact .title,
  #contact .sub-title {
    text-align: center;
  }
}

#contact .contact-form input,
#contact .contact-form textarea {
  border: 2px solid #c04355;
  color: #c04355;
  /* margin: auto; */
}

@media (min-width: 992px) {
  #contact .contact-form input {
    /* display: inline-block; */
    float: left;
    width: 200px;
  }

  #contact .contact-form textarea {
    width: 300px;
  }
}

@media screen and (max-width: 600px) {
  #contact .title,
  #contact-right .text {
    text-align: center;
  }

  #contact-right img {
    margin: 0 auto;
  }

  #contact .contact-form input,
  #contact .contact-form textarea {
    display: block;
    margin: 5px auto;
    width: 100%;
  }
}

#contact .title,
#contact .sub-title,
#contact-right.text {
  text-align: left;
  margin-left: 0;
}

#contact .contact-form textarea {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}

#contact .contact-form {
  margin-top: 30px;
  margin-bottom: 5px;
  position: relative;
}

#contact {
  position: relative;
}
/* #contact :before{content:"";position: absolute;left:50%; right: 0; top: 0; bottom: 0;background: rgba(195,68,86,0.8);} */
/* #contact-test{position: relative;min-height: 200px;}
#contact-test:before{content:"";position: absolute;left:50%; right: 0; top: 0; bottom: 0;background: rgba(195,68,86,0.2);} */
#contact-right {
  background-size: cover;
  position: relative;
}

#contact-right:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(195, 68, 86, 0.8);
}

#contact-right .title {
  width: 100%;
  border-bottom: 1px solid #fff;
}

#contact-right .text {
  margin-top: 10px;
}

#contact-right .text,
#contact-right .title,
#contact-right.sub-title {
  z-index: 2;
}

#contact-right .signature img {
  display: block;
  height: 70px;
  width: auto;
}
#footer {
  position: relative;
}

#footer .logo {
  height: 50px;
  width: auto;
  position: absolute;
  left: 50%;
}

#footer {
  padding-bottom: 60px;
  padding-top: 10px;
}
#copyright {
  padding-top: 10px;
  padding-bottom: 5px;
}

#copyright {
  border-top: 0.1px solid #fff;
}

.red {
  color: rgb(195, 68, 86);
}

.brush {
  font-family: "Brush";
}

.cr {
  color: #c04355;
}

.cg {
  color: #f6f6f6;
}

.cw {
  color: #fff !important;
}

.cb {
  color: #000;
}

.text-s {
  font-size: 2.2vh;
}

.text-sm {
  font-size: 2.5vh;
}

.text-m {
  font-size: 3vh;
}

.text-ml {
  font-size: 4vh;
}
.text-l {
  font-size: 8vh;
}

.outline {
  /* -webkit-text-stroke: 1px black; */
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.v-text {
  position: absolute;
  top: 65%;
  transform: rotate(-90deg);
  transform-origin: left top 0;
  float: left;
}

.v-text {
  font-size: 10px;
  width: 150px;
  margin: 2.5em -5em;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
}

.v-text:before,
.v-text:after {
  content: "";
  display: inline-block;
  width: 50%;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  border-bottom: 2px solid;
}

.v-text:after {
  margin: 0 -55% 0 0.5em;
}

.title {
  padding: 5px 0 20px;
}

.title,
.sub-title {
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 10%; */
}

.sub-title {
  margin-top: -15px;
}

.logo {
  display: block;
  max-width: 100px;
  height: auto;
}

/*NAV*/
.clearfix {
  *zoom: 1;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

@media (min-width: 992px) {
  .navbar {
    height: 80px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    position: relative !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}

.navbar-dark .navbar-nav .nav-link:hover {
  cursor: pointer;
}

.nav-link {
  position: relative;
  color: #000;
  text-decoration: none;
}

.nav-link:hover {
  color: #fff;
}

.nav-link:before {
  content: "";
  position: absolute;
  width: 20%;
  right: 50%;
  height: 2px;
  bottom: 0;
  left: 40%;
  background-color: #fff;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.nav-link:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/*arrow bounce*/
.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

/* Scroll down indicator (bouncing) */
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

/*arrow down end*/

input[type="text"],
input[type="tel"],
input[type="email"],
textarea {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  /* border: 1px solid #DDDDDD; */
}

input[type="submit"],
.action-btn {
  margin-top: 5px;
  color: #fff !important;
  background-color: #c04355;
  padding: 10px;
  display: block;
}

input[type="submit"]:hover,
.action-btn:hover {
  margin-top: 5px;
  color: #fff;
  background-color: rgba(192, 67, 85, 0.5);
}
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
textarea:focus {
  box-shadow: 0 0 5px rgb(192, 67, 85, 0.5);
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  /* border: 1px solid rgba(81, 203, 238, 1); */
}

.contact-social-icon-container a {
  display: block;
  background-color: #c04355;
  padding: 15px;
}
